.phone_link {
  display    : flex;
  align-items: center;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }

  img {
    margin-right: 6.7px;
  }
}