.signin__modal {

  &__title {
    margin     : 30px 0px;
    font-family: "GothamPro-Medium", sans-serif;
  }

  &__form {
    &__phone__input {
      .react-tel-input {
        .form-control {
          width: 100%;
        }
      }

    }

    &__recover__wrapper {
      text-align   : right;
      margin-bottom: 24px;

      &__password {
        color: var(--color_black2);

        &:hover,
        &:focus,
        &:active {
          color: var(--brand_color_red);
        }
      }
    }

    &__submit__btn.ant-btn-background-ghost.ant-btn {
      background-color: var(--brand_color_red);
      color           : #fff;
      border-radius   : 5px;
      border          : 1px solid var(--brand_color_red);
      font-family     : "GothamPro-Medium", sans-serif;
      margin-bottom   : 24px;
      text-transform  : uppercase;

      &:hover,
      &:focus,
      &:active {
        background-color: var(--brand_color_red);
        border-color    : var(--brand_color_red);
        color           : #fff;
      }
    }

    &__signup__btn.ant-btn.ant-btn-link {
      border        : 1px solid var(--color_black2);
      border-radius : 5px;
      color         : var(--brand_color_red);
      font-family   : "GothamPro-Medium", sans-serif;
      margin-bottom : 24px;
      text-transform: uppercase;
    }
  }

}

.signup__modal {

  &__title {
    margin     : 30px 0px;
    font-family: "GothamPro-Medium", sans-serif;
    text-align : center;
  }

  &__form {

    &__timeout__text {
      color     : var(--color_black2);
      margin    : 15px 0px;
      text-align: center;
    }

    &__btn__wrapper {
      display        : flex;
      justify-content: space-between;

      &__submit__btn.ant-btn-background-ghost.ant-btn {
        background-color: var(--brand_color_red);
        color           : #fff;
        border-radius   : 5px;
        border          : 1px solid var(--brand_color_red);
        font-family     : "GothamPro-Medium", sans-serif;

        &:hover,
        &:focus,
        &:active {
          background-color: var(--brand_color_red);
          border-color    : var(--brand_color_red);
          color           : #fff;
        }
      }

      &__back__btn.ant-btn-background-ghost.ant-btn {
        color        : var(--brand_color_black);
        border-radius: 5px;
        border       : 1px solid var(--brand_color_red);
        font-family  : "GothamPro-Medium", sans-serif;

        &:hover,
        &:focus,
        &:active {
          border-color: var(--brand_color_red);
          color       : var(--brand_color_black);
        }
      }
    }
  }

  &__steps.ant-steps {
    margin-bottom  : 30px;
    justify-content: space-around;

    .ant-steps-item {
      flex  : unset;
      margin: 0px;

      &-container {
        .ant-steps-item-icon {
          border          : none;
          background-color: var(--brand_color_red);
          width           : 24px;
          height          : 24px;

          .ant-steps-icon {
            display: none;
          }
        }

        .ant-steps-item-content {
          .ant-steps-item-title {
            &:after {
              display: none;
            }
          }
        }
      }
    }

    .ant-steps-item.ant-steps-item-process.ant-steps-item-active {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          border          : 8px solid var(--brand_color_red);
          background-color: #fff;
          width           : 24px;
          height          : 24px;
        }
      }
    }
  }
}

// error text

.auth__error__text {
  color        : var(--brand_color_red);
  display      : block;
  margin-bottom: 10px;
}