.product_view_right_info {
  .product_view_right_info_card {
    background-color: var(--color_white2);
    border          : 1px solid var(--gray6);
    border-radius   : 5px;
    padding         : 20px 20px 20px 30px;
    display         : flex;

    i {
      color     : var(--color_black2);
      font-size : 16px;
      margin-top: 2px;
    }

    .product_view_right_info_card_body {
      margin-left: 10px;

      .card_title {
        font-family: "GothamPro-Medium", sans-serif;
        font-weight: 700;
        font-size  : 16px;
        line-height: 29ox;
        color      : var(--brand_color_black);

      }

    }
  }


  .payment {
    display    : flex;
    align-items: flex-start;

    img {
      width: 22px;
    }

    ul {
      li {
        color      : var(--color_black2);
        line-height: 22px;
        list-style : disc inside;
      }
    }
  }
}

.boldP{
  font-weight: bold;
  font-size: 16px;
}