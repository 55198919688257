.personal__data__card {
  &__back__link {
    color: var(--brand_color_black);

    i {
      margin-right: 15px;
    }
  }

  &__title {
    color : var(--brand_color_black);
    margin: 35px 0px 20px;
  }

  &__form {
    &__change__psw {
      color: #fff;

      &__title {
        color: var(--brand_color_black);
      }

      &__input {
        margin-top     : 10px;
        display        : flex;
        justify-content: space-between;

        .input_wrapper {
          width: 48%;
        }
      }
    }

    &__error {
      display      : block;
      color        : var(--brand_color_red);
      margin-bottom: 20px;
    }
  }

  .personal__data__card__form__submitbtn.ant-btn {
    color           : #fff;
    background-color: var(--brand_color_red);
    border          : none;
    border-radius   : 5px;
  }
}