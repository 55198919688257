.mobile__menu {
  background-color: var(--brand_color_black);
  position        : fixed;
  bottom          : 0;
  width           : 100%;
  z-index         : 12;
  display         : none;

  &__list {
    display        : flex;
    justify-content: space-between;
    padding        : 16px 16px 8px;

    &__item {
      &__link {
        display       : flex;
        flex-direction: column;
        align-items   : center;

        &__img {
          width     : 24px;
          height    : 24px;
          object-fit: contain;
        }

        &__img.anticon {
          font-size: 20px;
          color    : #fff;
        }

        &__text {
          font-size  : 10px;
          line-height: 14px;
          color      : #fff;
        }

        .ant-badge-count {
          box-shadow: none;
        }
      }
    }
  }
}