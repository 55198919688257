.product_card {
  color: #000;
  border-radius: 5px;
  border: 1px solid var(--gray6);

  &:hover
    .ant-card-body
    .card_body
    .product_view_link
    figure
    .product_card_img {
    transform: scale(1.1);
  }

  .ant-card-body {
    padding: 0;

    .card_body {
      color: var(--brand_color_red);
      padding: 5px;
      text-align: center;
      position: relative;
      //
      height: 350px;
      .favorite {
        position: absolute;
        right: 3%;
        top: 5%;
        z-index: 100;
      }

      .discount {
        position: absolute;
        top: 22px;
        right: 22px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--brand_color_red);
        color: #fff;
        text-align: center;
        line-height: 40px;
      }

      .product_info {
        width: 76%;
        display: flex;
        margin: auto;
        flex-direction: column;
        align-items: flex-start;
        text-align: start;
        padding: 20px 0;

        .prices {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          gap: 15px;
          padding: 0 5px;
          height: 30px;
          align-items: center;
        }

        .price {
          font-weight: 700;
          font-size: 17px;
          line-height: 133.7%;
          /* identical to box height, or 23px */
          flex: none;
          order: 0;
          flex-grow: 0;
          text-align: center;

          color: #000000;
        }

        .oldprice {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 133.7%;
          /* identical to box height, or 19px */
          flex: none;
          order: 0;
          flex-grow: 0;
          text-align: center;
          text-decoration-line: line-through;

          color: rgba(0, 0, 0, 0.7);
        }
      }

      .product_view_link {
        display: block;

        figure {
          text-align: center;
          padding: 20px 15px 0px;

          .product_card_img {
            width: 90%;
            height: 180px;
            object-fit: contain;
            transition: all 0.3s;
          }
        }
      }

      .product_view_link {
        display: block;

        .product_name {
          padding-left: 5px;
          padding-right: 5px;
          font-size: 16px;
          color: #000;
          line-height: 15px;
          margin-top: 10px;
          overflow: hidden;
          font-weight: 400;
          font-size: 14px;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }

      .offer_end_title {
        color: var(--color_black2);
        margin-top: 10px;
      }

      .offer_end_in {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;

        .offer_end_date {
          color: var(--brand_color_black);
          position: relative;
          display: flex;
          flex-direction: column;

          &:after {
            background: var(--gray5);
            border-right: 1px solid var(--gray5);
            content: "";
            display: block;
            height: 1px;
            position: absolute;
            right: -25px;
            top: 12px;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            width: 23px;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }

          .offer_end_date_text {
            font-size: 8px;
            font-weight: 400;
            line-height: 14px;
            color: var(--color_black2);
          }
        }
      }
    }

    .card_footer {
      width: 100%;

      padding: 30px 15px;
      // height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 80%;
        color: white;
        background: linear-gradient(90deg, #ed3729 0%, #ff6f43 100%);
        border-radius: 4px;
        height: 49px;
      }
    }
  }
}
