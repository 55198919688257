.header_top_menus {
  padding: 30px 0px 0px;

  .mobile_header_menus {
    display: none;
  }

  &__body {
    margin-top: 30px;

    &__left {
      background-color: var(--color_white2);
      border-radius   : 5px;
      border          : 1px solid var(--gray6);

      &:not(.inside-drawer) {
        display: block;
      }

      &__item {
        display: block;

        &__link {
          display: flex;
          padding: 20px;

          &__img {
            width     : 30px;
            height    : 30px;
            object-fit: contain;
          }

          &__content {
            margin-left: 12px;

            &__text {
              color      : var(--color_black2);
              line-height: 17px;
              font-size  : 13px;
            }
          }
        }

        &__link.active {
          background-color: #fff;
        }
      }

    }



    &__content {
      color      : var(--brand_color_black);
      font-family: 'GothamPro', sans-serif !important;
    }

    .ant-btn.open_drower_btn.ant-btn-link {
      display         : none;
      background-color: var(--brand_color_black);
      border          : none;
      color           : #fff;
    }
  }

}

// b2b 

.b2b {
  margin-top: 30px;

  &__title {
    color: var(--brand_color_black);
  }

  &__body {
    &__title {
      color: var(--brand_color_black);
    }

    &__desc {
      color: var(--brand_color_black);
    }
  }

  &__form {
    margin-top: 30px;

    .input_wrapper,
    .textarea__wrapper {
      margin: 0;
    }

    .ant-upload.ant-upload-select {
      display         : block;
      width           : 100%;
      background-color: #fff;
      border          : 1px solid #d5d8df;
      box-sizing      : border-box;
      border-radius   : 6px;
      font-size       : 14px;
      line-height     : 24px;
      height          : 44px;

      .upload__btn.ant-btn.ant-btn-link {
        padding: 0;
        height : 100%;
        color  : var(--brand_color_black);
      }
    }

    &__btn {
      &__submit.ant-btn.ant-btn-link {
        border          : 1px solid var(--brand_color_red);
        border-radius   : 5px;
        color           : #fff;
        background-color: var(--brand_color_red);
      }
    }
  }

  .react-tel-input {
    .form-control {
      height      : 44px;
      width       : 100%;
      border-color: #d5d8df;
      color       : #393b3f;
      padding-left: 17px;
    }

    .flag-dropdown {
      display: none;
    }
  }

  .error__message {
    margin-top: 15px;
  }
}

.header__top__menus__drawer.ant-drawer {

  .ant-drawer-content-wrapper {

    .ant-drawer-header,
    .ant-drawer-body {
      padding: 16px 20px;
    }

    .ant-drawer-header-title {
      flex-direction: row-reverse;

      .ant-drawer-close {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .header_top_menus__body {
    .ant-btn.open_drower_btn.ant-btn-link {
      display: block;
    }

    &__left {
      &:not(.inside-drawer) {
        display: none;
      }
    }
  }
}

// drawer styles

.ant-drawer.ant-drawer-left.header__top__menus__drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {

        .ant-drawer-header {
          background-color: var(--brand_color_black);

          .ant-drawer-header-title {
            .ant-drawer-title {
              color      : #fff;
              font-family: "GothamPro-Medium", sans-serif;
            }

            .ant-drawer-close {
              color      : #fff;
              font-family: "GothamPro-Medium", sans-serif;
            }
          }
        }

        .ant-drawer-body {
          .header_top_menus__drawer__list {
            margin-top: 10px;

            .header_top_menus__drawer__list__item {
              .header_top_menus__drawer__list__item__divider {
                margin: 10px 0px;
              }

              .header_top_menus__drawer__list__item__link {
                color: var(--brand_color_black);

                &:hover,
                &:active,
                &:focus {
                  color: var(--color_black2);
                }
              }
            }
          }
        }
      }
    }
  }
}