.buy_button.ant-btn.ant-btn-link {
  border          : none;
  outline         : none;
  background-color: var(--brand_color_red);
  color           : #fff;
  border-radius   : 5px;
  font-family     : 'Nunito';
  font-style      : normal;
  font-weight     : 600;
  font-size       : 16px;
  cursor          : pointer;
}

.buy_button.checkout.ant-btn.ant-btn-link {
  background-color: var(--brand_color_black);
}