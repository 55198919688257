@media screen and (max-width: 1300px) {
  .mixel_wrapper {
    .header {
      .header_top {
        .desktop_header_top {
          .left {
            ul {
              li {
                margin-right: 30px;

                &:first-child {
                  margin-right: 60px;
                }
              }
            }
          }

          .right {
            ul {
              li {
                &:first-child {
                  margin-right: 40px;
                }
              }
            }
          }
        }
      }
    }

    .home_top_banner {
      .banner_wrapper {
        .swiper {
          .swiper-button-next {
            right: 0px;
          }

          .swiper-button-prev {
            left: 0px;
          }
        }
      }
    }

    .popular_categories_slider {
      .popular_slider_title {
        margin: 80px 0px 30px;
        color: var(--brand_color_black);
      }

      .slider_wrapper {
        position: relative;

        .swiper {
          .swiper-button-next {
            right: 0px;
          }

          .swiper-button-prev {
            left: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .mixel_wrapper {
    .header {
      .header_top {
        .desktop_header_top {
          .left {
            ul {
              li {
                margin-right: 10px;

                &:first-child {
                  margin-right: 20px;
                }
              }
            }
          }

          .right {
            ul {
              li {
                &:first-child {
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }

      .header_center {
        .header_centr {
          .header_center_right {
            height: 100%;
            display: block;
            width: 100%;

            ul {
              justify-content: space-between;
              align-items: center;
              height: 100%;

              li {
                margin-right: 25px;

                .right_item {
                  .user_nav_text {
                    display: none;
                  }
                }

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .mixel_wrapper {
    .header {
      .header_top {
        background-color: #fff;

        .desktop_header_top {
          display: none;

          .left {
            ul {
              li {
                margin-right: 10px;

                &:first-child {
                  margin-right: 20px;
                }
              }
            }
          }

          .right {
            ul {
              li {
                &:first-child {
                  margin-right: 15px;
                }
              }
            }
          }
        }

        .mobile_header_top {
          display: flex;
          justify-content: space-between;
        }
      }

      .header_center {
        .header_centr {
          display: none;
        }

        .mobile_header_centr {
          display: flex;
          justify-content: space-between;
        }
      }

      .header_bottom {
        .desktop_categories {
          display: none;
        }
      }
    }

    .home {
      padding-top: 0px;

      .home__loading {
        margin-top: 0px;
      }

      .home_top_banner {
        .mobile_open_categories {
          display: flex;
          justify-content: center;

          .ant-btn.ant-btn-primary.category_btn {
            padding: 0;
            width: 290px;
            height: 50px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .footer {
      margin-top: 40px;

      .social_links_box {
        padding-bottom: 30px;
      }
    }

    .cards_title_top {
      margin: 30px 0px 20px;
    }

    .header_top_menus {
      .desktop_menus_tabs {
        display: none;
      }

      .mobile_header_menus {
        display: block;
      }
    }

    .profile {
      &__sidebar {
        display: none;
      }

      .open_drower_btn.ant-btn-link {
        display: block;
      }
    }

    .fazo__back__top.ant-back-top {
      bottom: 100px;
    }
  }

  .ant-drawer-left.ant-drawer-open.mobile_categories_drower {
    .ant-drawer-content-wrapper {
      max-width: 100%;
    }
  }

  .ant-drawer.ant-drawer-left.ant-drawer-open.header_top_drower {
    .ant-drawer-content-wrapper {
      max-width: 100%;
    }
  }

  .mobile__menu {
    display: block;
  }

  // liveChat
  #__replain_widget {
    inset: auto 20px 70px auto !important;
  }
}

@media screen and (max-width: 768px) {
  .mixel_wrapper {
    .footer {
      padding-top: 20px;

      .footer_left_box {
        text-align: center;

        .footer_logo {
          margin-bottom: 20px;
        }

        .footer_lft_bottom {
          .contact_number_footer p {
            margin-top: 30px;
          }
        }
      }

      .footer_right_wrapper_item {
        text-align: center;
      }

      .social_links_box {
        justify-content: center;
      }
    }

    .order__card {
      &__right {
        &__name {
          &__text {
            margin-left: 10px;
          }

          .order__price {
            margin-left: 10px;
          }
        }

        &__info {
          &__order__price {
            display: none;
          }
        }
      }
    }

    .checkout_wrapper {
      .checkout_breadcrumb_area {
        .ant-breadcrumb.breadcrumb_comp {
          ol {
            li {
              display: none;

              &:first-child {
                display: block;
                position: relative;

                .ant-breadcrumb-separator {
                  position: absolute;
                  left: 0;
                  top: 0;

                  i {
                    transform: rotate(180deg);
                  }
                }

                .breadcrm_link {
                  margin-left: 25px;
                }
              }
            }
          }
        }
      }

      .checkout_body {
        .checkout_form_wrapper {
          .howto__obtain__address {
            display: block;

            .input_wrapper {
              width: 100%;

              &:last-child {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .home_top_banner {
      .banner_wrapper {
        .swiper {
          .swiper-button-next {
            display: none;
          }

          .swiper-button-prev {
            display: none;
          }
        }
      }
    }

    .popular_categories_slider {
      .popular_slider_title {
        margin: 80px 0px 30px;
        color: var(--brand_color_black);
      }

      .slider_wrapper {
        position: relative;

        .swiper {
          .swiper-button-next {
            display: none;
          }

          .swiper-button-prev {
            display: none;
          }
        }
      }
    }

    .profile {
      .order__history {
        &__body {
          &__collapse.ant-collapse {
            .ant-collapse-item {
              .ant-collapse-header {
                .ant-collapse-header-text {
                  .order__history__body__collapse__header {
                    padding: 15px 15px 40px;
                    display: block;

                    &__right {
                      width: 100%;
                      display: block;

                      &__left,
                      &__price {
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-end;
                      }
                    }

                    &__status {
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: flex-end;
                      max-width: unset;
                      width: 100%;
                    }
                  }

                  .order__history__body__collapse__expandebtn {
                    right: 50%;
                    top: 80%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .filter_wrapper {
      .filter_breadcrumb_area {
        .ant-breadcrumb.breadcrumb_comp {
          ol {
            li {
              display: none;

              &:first-child {
                display: block;
                position: relative;

                .ant-breadcrumb-separator {
                  position: absolute;
                  left: 0;
                  top: 0;

                  i {
                    transform: rotate(180deg);
                  }
                }

                .breadcrm_link {
                  margin-left: 25px;
                }
              }
            }
          }
        }
      }
    }

    .favorites {
      .favorite_breadcrumb_area {
        .ant-breadcrumb.breadcrumb_comp {
          ol {
            li {
              display: none;

              &:first-child {
                display: block;
                position: relative;

                .ant-breadcrumb-separator {
                  position: absolute;
                  left: 0;
                  top: 0;

                  i {
                    transform: rotate(180deg);
                  }
                }

                .breadcrm_link {
                  margin-left: 25px;
                }
              }
            }
          }
        }
      }
    }

    .profile {
      .profile_breadcrumb_area {
        .ant-breadcrumb.breadcrumb_comp {
          ol {
            li {
              display: none;

              &:first-child {
                display: block;
                position: relative;

                .ant-breadcrumb-separator {
                  position: absolute;
                  left: 0;
                  top: 0;

                  i {
                    transform: rotate(180deg);
                  }
                }

                .breadcrm_link {
                  margin-left: 25px;
                }
              }
            }
          }
        }
      }
    }

    .product_comparison {
      .product_comparison_breadcrumb_area {
        .ant-breadcrumb.breadcrumb_comp {
          ol {
            li {
              display: none;

              &:first-child {
                display: block;
                position: relative;

                .ant-breadcrumb-separator {
                  position: absolute;
                  left: 0;
                  top: 0;

                  i {
                    transform: rotate(180deg);
                  }
                }

                .breadcrm_link {
                  margin-left: 25px;
                }
              }
            }
          }
        }
      }
    }

    .search_result_wrapper {
      .breadcrumb_area {
        .ant-breadcrumb.breadcrumb_comp {
          ol {
            li {
              display: none;

              &:first-child {
                display: block;
                position: relative;

                .ant-breadcrumb-separator {
                  position: absolute;
                  left: 0;
                  top: 0;

                  i {
                    transform: rotate(180deg);
                  }
                }

                .breadcrm_link {
                  margin-left: 25px;
                }
              }
            }
          }
        }
      }
    }

    .product_view {
      .breadcrumb_area {
        .ant-breadcrumb.breadcrumb_comp {
          ol {
            li {
              position: relative;

              span {
                display: none;
              }

              .ant-breadcrumb-separator {
                position: absolute;
                left: 0;
                top: 0;
                display: block;

                i {
                  transform: rotate(180deg);
                }
              }

              span.last__one {
                display: block;
                position: relative;
                margin-left: 25px;
              }

              &:last-child {
                display: none;
              }
            }
          }
        }
      }
    }

    .header_top_menus {
      &_breadcrumb_area {
        .ant-breadcrumb.breadcrumb_comp {
          ol {
            li {
              display: none;

              &:first-child {
                display: block;
                position: relative;

                .ant-breadcrumb-separator {
                  position: absolute;
                  left: 0;
                  top: 0;

                  i {
                    transform: rotate(180deg);
                  }
                }

                .breadcrm_link {
                  margin-left: 25px;
                }
              }
            }
          }
        }
      }
    }

    .fazo__back__top.ant-back-top {
      bottom: 70px;
    }
  }

  .installment_modal {
    .modal_top_wrapper {
      .modal_top_flex_box {
        display: block;

        .modal_option_block {
          display: flex;
          justify-content: space-between;
          margin: 10px 0px 0px;

          .product__counter__wrapper {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .mixel_wrapper {
    .checkout_wrapper {
      .checkout_body {
        .checkout_form_wrapper {
          .howto__obtain__selects {
            display: block;

            .select__wrapper {
              width: 100%;
              margin-top: 25px;
            }
          }
        }
      }
    }
  }

  .cart__modal.ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        .cart__modal__total__count__price {
          &__text {
            text-align: left;
          }
        }

        .cart__modal__btns {
          display: block;

          &__continue__btn,
          &__checkout__btn {
            display: block;
            width: 100%;
          }

          &__checkout__btn {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .ant-modal-wrap {
    .cart__modal.ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .cart__modal__product__card__right {
            flex-direction: column;

            &__count {
              margin: 0px;
            }

            &__btns {
              margin: 15px 0px;
            }
          }
        }
      }
    }
  }

  .installment_modal {
    .modal_top_wrapper {
      .modal_top_flex_box {
        .modal_left_block {
          .product__img {
            width: 100px;
          }
        }
      }
    }
  }
}
