.home_center_banner {
  background-color: var(--color_white2);
  margin-top      : 80px;
  padding         : 30px 0px;

  .center_banner_wrapper {
    position: relative;

    .swiper {
      position: static;

      .center_banner_card {

        .left {
          display        : flex;
          flex-direction : column;
          justify-content: center;
          height         : 100%;
          padding-left   : 20px;

          .left_card {

            width: 90%;

            .banner_title {
              font-style : normal;
              font-weight: 500;
              font-size  : 42px;
              line-height: 57px;
              color      : var(--brand_color_black);
            }

            .banner_desc {
              font-weight: 400;
              font-size  : 16px;
              line-height: 26px;
              color      : var(--color_black2);
              margin-top : 10px;
            }
          }
        }

        .center {
          text-align: center;

          img {
            height    : 277px;
            width     : 310px;
            object-fit: cover;
            max-width : 100%;

          }
        }

        .right {
          display        : flex;
          flex-direction : column;
          justify-content: center;
          align-items    : center;
          height         : 100%;

          .price {
            color: var(--brand_color_red);
          }

          .old_price {
            font-weight: 700;
            font-size  : 20px;
            line-height: 27px;
            display    : block;
            color      : var(--color_black2);
          }

          .banner_link {
            font-weight  : 600;
            font-size    : 16px;
            line-height  : 22px;
            padding      : 12px 50px;
            border-radius: 5px;
            color        : var(--brand_color_red);
            border       : 1px solid var(--brand_color_red);
            margin-top   : 30px;
          }
        }
      }


      .swiper-button-next {
        right           : -90px;
        width           : 50px;
        height          : 50px;
        border-radius   : 50%;
        background-color: #F7F7F7;
        border          : 1px solid #E0E0E0;

        &:after {
          font-size: 14px;
          color    : #808080;
        }
      }

      .swiper-button-prev {
        left            : -90px;
        width           : 50px;
        height          : 50px;
        border-radius   : 50%;
        background-color: #F7F7F7;
        border          : 1px solid #E0E0E0;

        &:after {
          font-size: 14px;
          color    : #808080;
        }
      }

    }
  }
}