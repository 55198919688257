.header {
  position: relative;

  .header_top {
    padding: 12px 0px;
    max-height: 50px;
    

    .desktop_header_top {
      display: flex;
      justify-content: space-between;

      .left {
        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          color: #000;

          li {
            margin-right: 40px;
            color: rgb(0, 0, 0);
            a {
              color: rgb(0, 0, 0);
             
              &:focus,
              &:active {
                color: rgb(0, 0, 0);
              }

              &:hover{
                color: rgba(237, 55, 41, 1);
              }
            }

            &:first-child {
              margin-right: 80px;
            }
          }
        }
      }

      .right {
        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          height: 100%;
          a {
            color: #000;
          }
          li {
            .lang_text {
              font-weight: 500;
              cursor: pointer;
              color: #000;
            }

            &:first-child {
              margin-right: 59px;
            }
          }
        }
      }
    }

    .mobile_header_top {
      display: none;

      .logo {
        .logo_img {
          height: 23px;
          width: 120px;
          object-fit: contain;
        }
      }
    }
  }

  .header_center {
    background: #fff;

    padding:0 30px ;

    .header_centr {
      padding: 25px 0px;

      .header_center_left {
        .logo {
          .logo_img {
            height: 50px;
            width: 160px;
            object-fit: contain;
          }
        }
      }

      .header_center_middle {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .header_center_right {
        display: flex;
        justify-content: flex-end;

        ul {
          display: flex;

          li {
            margin-right: 40px;
            .icons {
              color: black;
              font-size: 30px;
            }
            .user_nav_text {
              display: inline-block;
              margin-top: 5px;
              color: var(--brand_color_black);
            }

            &:last-child {
              margin-right: 0;
            }

            .right_item {
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;

              .ant-badge-count {
                background-color: var(--brand_color_yellow);
                font-weight: bold;
              }

              img,
              i {
                height: 26px;
                color: var(--brand_color_black);
                font-size: 26px;
              }
            }

            &:hover {
              .icons,
              .user_nav_text {
                color: rgba(237, 55, 41, 1);
              }
            }
          }
        }
      }
    }

    .mobile_header_centr {
      display: none;
      padding: 20px 0px 10px;

      .open_drower_btn {
        background-color: var(--brand_color_red);
        border: none;
        border-radius: 5px;
        padding: 0px 5px 1px;
        margin-right: 10px;
      }
    }
  }

  .ant-affix {
    .header_center {
      .header_centr {
        padding: 20px 0px 10px;
      }
    }
  }

  .header_bottom {
    background: #fff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    padding:0 80px ;
    .categories {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .category {
        a {
          color: #000;
          font-size: 16px;
          line-height: 29px;
          font-family: "GothamPro-Medium", sans-serif;
        }

        .active {
          color: var(--brand_color_yellow);
        }
      }
    }

    .dropDown {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 100%;
      align-items: center;
      @media screen and (max-width: 992px) {
        display: none;
      }

      .box {
        position: relative;
        // width: 50%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;

        .inner {
          width: 200px;
          display: none;
          position: absolute;
          height: 400px;
          top: 100%;
          left: -10%;
          padding: 16px;
          flex-direction: column;
          gap: 15px;
          z-index: 200;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
            rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
          background-color: #fff;

          a {
            cursor: pointer;
            text-decoration: none;
            font-weight: 600;
            color: #000;
            padding: 0 10px;

            &:hover {
              color: rgb(255, 255, 255);
              background: #ff6f43;
            }
          }
        }
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        h3 {
          cursor: pointer;
          font-weight: 600;
        }
      }

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

// drower styles

.header_top_drower {
  .ant-drawer-header {
    padding: 16px;

    .ant-drawer-header-title {
      flex-direction: row-reverse;

      .logo {
        img {
          width: 172px;
          height: 33px;
          object-fit: contain;
        }
      }
    }
  }

  .ant-drawer-body {
    padding: 16px;

    .reg_area {
      display: flex;

      .left {
        width: 50%;
        display: flex;
        align-items: center;
        position: relative;

        .sign_in_btn {
          border: none;
          outline: none;
          background-color: transparent;
          cursor: pointer;
          color: var(--brand_color_black);

          .user_wrapper {
            border-radius: 50%;
            width: 42px;
            height: 42px;
            background-color: var(--gray6);
            display: inline-block;
            line-height: 38px;
            text-align: center;
            margin-right: 10px;

            img {
              width: 17.14px;
              height: 19.29px;
            }
          }
        }

        &:after {
          position: absolute;
          right: 0;
          height: 29px;
          width: 1px;
          content: "";
          border-right: 1px solid var(--gray5);
        }
      }

      .right {
        width: 50%;
        display: flex;
        align-items: center;
        // justify-content: flex-end;
        justify-content: center;

        .sign_up_btn {
          border: none;
          outline: none;
          background-color: transparent;
          cursor: pointer;
          color: var(--brand_color_black);
        }
      }

      .right_item {
        .reg_area__user__text {
          color: var(--brand_color_black);
          margin-left: 16px;
        }
      }
    }

    .menu_wrapper {
      margin-top: 40px;

      .menu {
        a {
          color: var(--brand_color_black);

          img {
            margin-right: 17px;
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
        }

        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .langs_area {
      margin-top: 40px;

      .lang_btn {
        background-color: transparent;
        border: 1px solid var(--color_black2);
        margin-right: 9px;
        color: var(--brand_color_black);
        padding: 0px 17px;
        border-radius: 5px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .lang_btn.active {
      background-color: var(--brand_color_red);
      border-color: var(--brand_color_red);
      color: #fff;
    }

    .tel_area {
      margin-top: 40px;

      .phone_link {
        strong {
          color: var(--brand_color_black);
          font-weight: 400;
        }
      }
    }
  }
}

// mobile categories drower

.mobile_categories_drower {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          padding: 16px;

          .ant-drawer-header-title {
            flex-direction: row-reverse;

            .logo {
              .logo_img {
                width: 140px;
                height: 30px;
                object-fit: contain;
              }
            }

            .ant-drawer-close {
              margin-right: 0;
            }
          }
        }

        .ant-drawer-body {
          padding: 0px;

          .ant-collapse {
            .ant-collapse-item {
              .ant-collapse-header {
                .mobile_categories_drower__panel__header {
                  color: var(--brand_color_black);

                  span {
                    margin-left: 15px;
                  }
                }

                &:hover {
                  background-color: var(--gray6);
                }
              }

              .ant-collapse-content {
                .ant-collapse-content-box {
                  a {
                    padding: 10px 20px;
                    border-radius: 5px;
                    color: var(--brand_color_black);
                    display: block;

                    &:hover {
                      background-color: var(--gray6);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
