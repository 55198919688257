.checkout_wrapper {
  min-height: calc(100vh - 687px);
  padding   : 30px 0px 0px;


  .checkout_body {
    .checkout_form_wrapper {

      .top_title_area {
        display    : flex;
        align-items: center;

        .title_number {
          width        : 35px;
          height       : 35px;
          border       : 1px solid var(--gray5);
          border-radius: 5px;
          text-align   : center;
          line-height  : 35px;
          color        : var(--brand_color_black);
        }

        .top_title {
          margin-left: 10px;
          color      : var(--brand_color_black);

        }
      }

      .checkout_form_title {
        margin: 25px 0px;
      }

      .your_data {
        .top_title_area {
          margin-bottom: 25px;

        }

        &__error__message {
          color  : var(--brand_color_red);
          display: block;
        }

        .react-tel-input {
          .form-control {
            height      : 44px;
            width       : 100%;
            border-color: #d5d8df;
            color       : #393b3f;
            padding-left: 17px;
          }

          .flag-dropdown {
            display: none;
          }
        }

      }

      .your__orders {
        &__top {
          display        : flex;
          justify-content: space-between;

          .outline__custom__btn {
            color       : var(--brand_color_black);
            border-color: var(--gray5);
          }
        }
      }

      .payment__types {
        margin-top: 25px;
        max-width : 500px;
        width     : 100%;

        &__radios {
          margin-top: 25px;
          width     : 100%;

          .ant-radio-wrapper {
            width        : 100%;
            padding      : 15px;
            border       : 1px solid var(--gray5);
            border-radius: 5px;
            color        : var(--brand_color_black);

            .ant-radio {
              &:hover {
                .ant-radio-inner {
                  border-color: var(--brand_color_red);
                }
              }



              .ant-radio-inner {
                &::after {
                  background-color: var(--brand_color_red);
                }
              }
            }
          }

          .ant-radio-wrapper.ant-radio-wrapper-checked {
            border-color: var(--brand_color_red);

            &::after {
              border-color: var(--brand_color_red);
            }

            .ant-radio-input:focus+.ant-radio-inner {
              border-color: var(--brand_color_red);
            }


            .ant-radio-checked .ant-radio-inner {
              border-color: var(--brand_color_red);

              &::after {
                background-color: var(--brand_color_red);
              }

            }
          }
        }
      }

      .payment-type-content {
        color: var(--brand_color_black);

        .payment-type {
          margin-bottom: 15px;
          margin-top   : 20px;
          font-weight  : 700;
        }

        .item-1 {
          margin-bottom: 15px;
        }

        .payment-type-item {
          .payment__type__phone__input {
            margin-right: 20px;

            .react-tel-input {

              .selected-flag {
                display: none;
              }

              .form-control {
                height      : 40px;
                width       : 100%;
                padding-left: 17px;
              }
            }
          }

          .payment__type__password {
            .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
              border-color: #d9d9d9;
            }

            .ant-input-affix-wrapper:focus,
            .ant-input-affix-wrapper-focused {
              border-color: #d9d9d9;
              box-shadow  : none;
            }
          }

          .ant-form-item {
            display: inline-block;

          }

          .register-not {
            color    : rgb(233, 22, 22) !important;
            font-size: 12px;

            a {
              color: #2d63ce;
            }
          }

          .register-not.mt-5 {
            margin-top: 5px;
          }

          span:last-child {
            color      : #393b3f;
            font-weight: 600;
          }
        }

        .outline__custom__btn {
          border-color: var(--gray5);
          color       : var(--brand_color_black);
          margin-top  : 15px;
        }
      }

      .howto__obtain {
        margin-top: 25px;

        .howto__obtain__label {
          display      : block;
          color        : var(--color_black2);
          margin       : 25px 0px 10px;
          // cursor    : pointer;
        }

        .city {
          padding      : 15px;
          border       : 1px solid var(--gray5);
          border-radius: 5px;
          margin-top   : 5px;
          max-width    : 400px;
          width        : 100%;
          color        : var(--brand_color_black);
          user-select  : none;

          &__text {
            color      : var(--color_black2);
            margin-left: 10px;
          }

          .ant-radio {
            color: var(--brand_color_black);

            &:hover {
              .ant-radio-inner {
                border-color: var(--brand_color_red);
              }
            }

            .ant-radio-inner {
              &::after {
                background-color: var(--brand_color_red);
              }
            }
          }

          .ant-radio.ant-radio-checked {
            .ant-radio-inner {
              border-color: var(--brand_color_red);
            }
          }

        }


        &__address {
          margin-top     : 25px;
          display        : flex;
          justify-content: space-between;

          .input_wrapper {
            width: 80%;

            &:last-child {
              width: 18%;
            }
          }
        }


        &__datapicker__comp {
          position: relative;

          .date__picker {
            width: 100%;

            .ant-picker-input {
              input {
                font-size: 14px;
              }
            }
          }

          .custom__label {
            position        : absolute;
            top             : 10px;
            left            : 13px;
            padding         : 0px 4px 0px 4px;
            pointer-events  : none;
            transform       : scale(0.95) translateY(-1.4rem) translateX(-0.1rem);
            background-color: #fff;
            transition      : all 0.3s ease-in-out;
            color           : #767676;
            margin          : 0;
            font-size       : 14px;
            line-height     : 24px;
          }

          .error_message {
            color    : var(--brand_color_red);
            font-size: 14px;
          }
        }

      }

    }

    .checkout__form {
      &__right {
        background-color: var(--color_white2);
        border          : 1px solid var(--gray5);
        border-radius   : 5px;
        padding         : 30px 20px;

        &__block {
          display        : flex;
          justify-content: space-between;
          margin-top     : 5px;
          align-items    : center;

          p {
            color: var(--brand_color_black);
          }

          p,
          h3 {
            margin-top: 10px;
          }
        }

        .ant-divider-horizontal {
          margin: 15px 0px;
        }

        &__submit__btn.ant-btn.ant-btn-link {
          margin          : 15px 0px;
          background-color: var(--brand_color_red);
          border          : none;
          border-radius   : 5px;
          color           : #fff;
        }

        &__accept__terms {
          color: var(--brand_color_black);

          &__link {
            color: var(--blue1);
          }
        }

        &__error__message {
          color  : var(--brand_color_red);
          display: block;
        }
      }
    }
  }
}

.error__message {
  color  : var(--brand_color_red);
  display: block;
}