.new__products {
  min-height: calc(100vh - 634px);
  padding: 30px 0px 0px;
  color: var(--brand_color_black);

  .filter_breadcrumb_area {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .search_result_body {
    .filter_form {
      margin-top: 25px;
      border: 1px solid var(--gray6);
      border-radius: 5px;
    }

    .search_right_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .right_top_filter {
        display: flex;

        button {
          border: none;
          background-color: transparent;
          cursor: pointer;
          display: flex;
          align-items: center;

          img {
            margin-right: 8px;
          }
        }
      }

      .right_top_change_grid {
        button {
          border: none;
          background-color: transparent;
          cursor: pointer;

          &:last-child {
            margin-left: 5px;
          }
        }
      }
    }

    // .button_area {
    //   display: flex;
    //   justify-content: center;
    //   margin-top: 60px;
    // }
    .search_pagination_block {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .search_showmore_btn {
        margin-top: 30px;
      }
    }
  }
}
