.home_top_banner {
  .banner_wrapper {
    position: relative;

    // swiper custon styles
    .swiper {
      position: static;

      .swiper-wrapper {
        .swiper-slide {
          .banner__link {
            display: block;

            &__img {
              width     : 100%;
              height    : 360px;
              object-fit: cover;
            }
          }
        }
      }

      .swiper-button-next {
        right           : -30px;
        width           : 50px;
        height          : 50px;
        border-radius   : 50%;
        background-color: #F7F7F7;
        border          : 1px solid #E0E0E0;

        &:after {
          font-size: 14px;
          color    : #808080;
        }
      }

      .swiper-button-prev {
        left            : -30px;
        width           : 50px;
        height          : 50px;
        border-radius   : 50%;
        background-color: #F7F7F7;
        border          : 1px solid #E0E0E0;

        &:after {
          font-size: 14px;
          color    : #808080;
        }
      }

      .swiper-pagination {
        bottom: -30px;

        .swiper-pagination-bullet {
          background-color: var(--gray5);
          opacity         : 1;
        }

        .swiper-pagination-bullet-active {
          background-color: var(--brand_color_red);

        }

      }
    }
  }

  .mobile_open_categories {
    display   : none;
    margin-top: 65px;
  }
}