.comment{
  width: 70%;
  h1{
    font-weight: bold;
    color: var(--brand_color_yellow);
  }
}



.ant-input:focus {
  border-color: var(--brand_color_red);
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
  box-shadow: 0 0 0 2px rgba(87,168,233, .2);
}


.titleComment{
  padding: 4px 20px;
  display:flex;
  justify-content:space-between;
  width:100%;
  border-top-right-radius:8px;
  border-top-left-radius:8px;

  p:nth-child(1){
    color: var(--brand_color_yellow);
    font-weight: 900;
    //text-decoration-line: underline;
  }
}

.mainContent{
  width: 100%;
  padding: 4px 30px 4px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  div{
    display:flex;
    justify-content:space-between;
    width:100%;
  }
  p{
    width: 80%;
  }

  ul{
    li{
      width: 15%;
    }
  }
}



@media (max-width: 1000px) {
  .comment {
    width:100%;
  }
}



@media (max-width: 770px) {
  .comment {
    width:100%;
    .mainContent{
      width: 100%;

      div:nth-child(1){
        display: flex;
        padding: 0px 0px 0px 0px;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        p{
          //align-self: flex-start;
        }
        ul{

          //align-self: flex-end;
          width: 20%;
          li{
            width: 30%;

            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 367px) {
  .comment {
    width:100%;
    .mainContent{
      width: 100%;

      div:nth-child(1){
        display: flex;
        padding: 0px 0px 0px 0px;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        p{
          align-self: flex-start;
        }
        ul{
          align-self: flex-end;
          justify-self: flex-end;
          width: 40%;
          li{
            width: 20%;

            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}


.panigationCostum{
  color: var(--brand_color_yellow);
}
.ant-pagination-item-link:hover{
  border: 1px solid var(--brand_color_yellow);

  span{
    color: var(--brand_color_yellow);
    border-color: red;
  }

}

.ant-pagination-item-active {


  a{
  color: var(--brand_color_yellow);
  }
}

.ant-pagination-item a {
  border-color: var(--brand_color_yellow)!important;

  &:hover{
    color: var(--brand_color_yellow);
  }
}
