@import "variables";
@import "globalClasses";
// NURITO FONT
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
// GothamPro FONT

@import "fonts";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 14px;
  line-height: 25px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  font-family: "GothamPro", sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
b,
a {
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  list-style: none;
}

a {
  color: #fff;
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

::-webkit-scrollbar,
::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-track {
  &:hover {
    background-color: var(--gray5);
  }
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color_black2);
  border: 5px solid #fff;
  border-radius: 16px;

  &:hover {
    border-width: 4px;
  }
}

::-webkit-scrollbar,
::-webkit-scrollbar-track {
  background-color: #fff;
}

.ant-affix {
  z-index: 11;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.ant-breadcrumb.breadcrumb_comp {
  span {
    .ant-breadcrumb-link {
      .ant-breadcrumb-separator {
        color: var(--daraja2);
      }

      .breadcrm_link {
        color: var(--daraja2);
      }
    }

    &:last-child .ant-breadcrumb-link .breadcrm_link {
      color: #000;
      font-weight: 600;
    }
  }
}

.ant-switch {
  .ant-switch-handle {
    &::before {
      background-color: var(--brand_color_red);
    }
  }
}

button[type="submit"].ant-btn-link {
  font-family: "GothamPro-Medium", sans-serif;
}

.fazo__back__top.ant-back-top {
  bottom: 30px;
  right: unset;
  left: 30px;
}

// confirm modal

.ant-modal-confirm-btns {
  .ant-btn.ant-btn-primary {
    background-color: var(--brand_color_red);
    color: #fff;
    border-radius: 5px;
    border-color: var(--brand_color_red);
  }
}

// ant modal

.ant-modal-wrap.auth__modal {
  z-index: 1111;
}

// notificateion

.ant-notification-notice-description {
  padding-left: 35px;
}

.swiper-button-prev.swiper-button-disabled {
  display: none;
}

.swiper-button-next.swiper-button-disabled {
  display: none;
}

// live chat
#__replain_widget {
  #__replain_widget_iframe {
    html {
      body {
        #app {
          top: unset !important;
          bottom: 0;
          height: 50%;
        }
      }
    }
  }
}

// mobile styles
@import "mobile";
