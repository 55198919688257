.input_wrapper {
  width        : 100%;
  position     : relative;
  margin-bottom: 25px;

  .custom_input {
    width           : 100%;
    background-color: #fff;
    border          : 1px solid #d5d8df;
    box-sizing      : border-box;
    border-radius   : 6px;
    font-size       : 14px;
    line-height     : 24px;
    padding         : 0 17px;
    height          : 44px;
    color           : #393b3f;

    &:focus {
      box-shadow: none;
      outline   : none;

      +label {
        transform       : scale(0.95) translateY(-1.4rem) translateX(-0.1rem);
        background-color: #fff;
      }
    }

    &:not(:placeholder-shown) {
      +label {
        transform       : scale(0.95) translateY(-1.3rem) translateX(-0.1rem);
        background-color: #fff;
      }
    }

    &::placeholder {
      opacity: 0;
    }
  }

  .custom_label {
    position        : absolute;
    top             : 10px;
    left            : 13px;
    padding         : 0px 4px 0px 4px;
    pointer-events  : none;
    transform-origin: 0 0;
    transition      : all 0.3s ease-in-out;
    color           : #767676;
    margin          : 0;
    font-size       : 14px;
    line-height     : 24px;
  }

  .error_message {
    color    : var(--brand_color_red);
    font-size: 14px;
  }
}