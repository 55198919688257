.rightContent{
  color: black;
}

.addBasket{
  background: linear-gradient(90deg, #ED3729 0%, #FF6F43 100%);
  color: white;
  width: 100%;
  padding: 12px 0;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-top: 20px;
}


.buttunOther{
  background: #EFF1F5;
  border-radius: 8px;
  width: 100%;
  padding: 13px 0;
}
