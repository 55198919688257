.footer {
  background: #000000;
  padding: 80px 40px;
  margin-top: 80px;

  .footer_left_box {
    .footer_logo {
      margin-bottom: 62px;
      width: 140px;
      height: 50px;
      object-fit: contain;
    }

    .footer_lft_bottom {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        color: #ffffff;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        color: #ffffff;
      }

      .contact_number_footer {
        p {
          margin-top: 59px;
          font-weight: 400;
          font-size: 14px;
          line-height: 180%;
          color: #ffffff;
        }

        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 180%;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }

  .footer_right_wrapper_item {
    h2 {
      margin-bottom: 20px;
      color: #fff;
    }

    ul {
      li {
        margin-bottom: 10px;

        a {
          font-weight: 400;
          font-size: 18px;
          line-height: 160%;
          color: #909090;

          &:hover {
            color: #a7a7a7;
          }
        }
      }
    }
  }

  .footer_row_bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
    margin-top: 40px;

    @media (max-width: 990px) {
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      height: auto;
    }

    .social_links_box {
      display: flex;
      gap: 10px;
      height: 100%;
      align-items: center;
      @media (max-width: 990px) {
        flex-direction: column;
        justify-content: center;
      }

      .social_btns {
        display: flex;
        gap: 10px;
      }

      .social_btn {
        width: 35px;
        height: 35px;
        font-size: 30px;
        background: transparent;
        text-align: center;
        line-height: 25px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        margin-right: 10px;

        &:hover {
          color: var(--brand_color_red);
        }
      }
    }

    .call-center {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 40px;

      .center-inner {
        display: flex;
        padding: 10px 0;
        gap: 10px;

        a {
          &:hover {
            color: var(--brand_color_red);
          }
        }
      }
    }
  }
}
