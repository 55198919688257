.product_desc {
  width: 100%;

  .product_name {
    color        : var(--brand_color_black);
    margin-bottom: 5px;
  }

  &__code {
    color        : var(--color_black2);
    margin-bottom: 30px;
  }

  .action_area {
    margin-bottom  : 30px;
    display        : flex;
    justify-content: space-between;
    gap            : 16px;

    .product_price {
      color: var(--brand_color_black);
    }

    .right {
      width: 210px;

      .card_footer {
        ul {
          display        : flex;
          justify-content: space-between;

          li {
            width     : 33%;
            text-align: center;
            position  : relative;

            &:after {
              background       : var(--gray6);
              border-right     : 1px solid var(--gray6);
              content          : "";
              display          : block;
              height           : 1px;
              position         : absolute;
              right            : -12px;
              top              : 12px;
              -webkit-transform: rotate(90deg);
              transform        : rotate(90deg);
              width            : 24px;
            }

            button {
              border          : none;
              outline         : none;
              box-shadow      : none;
              background-color: transparent;
              cursor          : pointer;

              i {
                color    : var(--gray4);
                font-size: 18px;
              }
            }


          }
        }
      }
    }
  }

  .button_area {
    display      : flex;
    flex-wrap    : wrap;
    gap          : 16px;
    margin-bottom: 55px;
    flex         : 1 1 auto;

    button {
      margin-left: 0;
    }
  }

  &__area {
    &__title {
      color        : var(--brand_color_black);
      margin-bottom: 10px;

    }

    &__content {
      color: var(--brand_color_black);

      table {
        display: block;
      }
    }
  }

}

@media (max-width: 425px) {
  .action_area {
    flex-direction: column;
    align-items   : center;
  }

  .button_area {
    .buy_button {
      max-width: 100%;
      width    : 100%;
    }
  }
}