.BrandsCarusel_wrapper {
  margin-bottom: 55px;

  .brands_container {
    position   : relative;
    user-select: none;
  }

  .BrandsCarousel_title_navigation {
    margin-top: 50px;

    .BrandsCarusel_title {
      color        : var(--brand_color_black);
      margin-bottom: 40px;
    }
  }

  .brand_slider_wrapper {
    .ant-card-body {
      padding      : 0;
      height       : 120px;
      width        : auto;
      border-radius: 5px;
      border       : 1px solid var(--gray5);
      line-height  : 120px;
    }
  }

  .swiper {
    position      : static;
    padding-bottom: 20px;

    .swiper-button-next {
      right : 5px;
      width : 30px;
      height: 50px;
      top   : 10px;

      &:after {
        content    : "\f178";
        font-family: "Font Awesome 6 Free";
        font-size  : 16px;
        font-weight: 900;
        color      : var(--color_black2);
      }
    }

    .swiper-button-prev {
      left  : auto;
      right : 71px;
      width : 30px;
      height: 50px;
      top   : 10px;

      &::after {
        content    : "\f177";
        font-family: "Font Awesome 6 Free";
        font-size  : 16px;
        font-weight: 900;
        color      : var(--color_black2);
      }
    }

    .swiper-slide {
      text-align: center;

      .BrandsCarusel_img_wrapper {
        width  : 100%;
        height : 100%;
        display: block;

        img {
          display   : block;
          width     : 100%;
          height    : 100%;
          object-fit: contain;
        }
      }
    }
  }
}

// @media screen and (min-width: 320px) {
//   .brandsSwiper {
//     width: 320px;
//   }
// }
// @media screen and (min-width: 640px) {
//   .brandsSwiper {
//     width: 640px;
//   }
// }

// @media screen and (min-width: 768px) {
//   .brandsSwiper {
//     width: 768px;
//   }
// }