.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: var(--brand_color_red)!important;
  font-weight: 500;
}



.ant-tabs-tab:hover{
  color: var(--brand_color_red)!important;
}

.ant-tabs-tab-active:hover{
  color: var(--brand_color_red)!important;
}


.ant-tabs-tab-btn:hover{
  color: var(--brand_color_red)!important;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: var(--brand_color_red);
  pointer-events: none;
  color: red;
}


.ant-tabs-ink-bar:hover {
  position: absolute;
  background: red;
  pointer-events: none;
  color: red;
}