.mobile_search {

  .ant-input-group {

    .ant-input {
      border-top               : 1px solid var(--brand_color_red);
      border-left              : 1px solid var(--brand_color_red);
      border-bottom            : 1px solid var(--brand_color_red);
      border-top-left-radius   : 5px;
      border-bottom-left-radius: 5px;
    }

    .ant-input-group-addon {

      .ant-input-search-button {
        background-color: var(--brand_color_red);
        border-color    : var(--brand_color_red);
        text-shadow     : none;
        box-shadow      : none;

        .search_btn_text {
          font-size  : 16px;
          font-weight: 600;
          line-height: 28px;
          margin-left: 12px;
        }
      }

    }
  }
}