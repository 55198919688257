.pagination__area {
  margin-top     : 30px;
  display        : flex;
  justify-content: center;

  .ant-pagination-item {
    width      : 36px;
    height     : 36px;
    margin     : 0;
    padding    : 0;
    border     : none;
    line-height: 36px;

    a {
      color      : var(--brand_color_black);
      font-family: 'Nunito';
      font-style : normal;
      font-weight: 400;
      font-size  : 18px;

    }
  }

  .ant-pagination-item.ant-pagination-item-active {
    border       : 1px solid var(--brand_color_red);
    border-radius: 5px;
    line-height  : 34px;

  }

  .ant-pagination-prev {
    .ant-pagination-item-link {
      border: none;

    }
  }

  .ant-pagination-next {
    .ant-pagination-item-link {
      border: none;

    }
  }


}