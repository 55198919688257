.installment_modal {

  .installment_modal_title {
    color: var(--brand_color_black);
  }

  .ant-modal-header {
    border-bottom: none;
    padding-top  : 40px;
  }

  .ant-modal-close {
    padding-top: 26px;
  }

  .ant-modal-body {

    .modal_top_wrapper {
      margin-bottom: 60px;

      .modal_top_flex_box {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        margin-bottom  : 40px;

        &:last-child {
          margin-bottom: 0;
        }

        .modal_left_block {
          display    : flex;
          align-items: center;

          .product__img {
            width       : 150px;
            height      : 100px;
            margin-right: 16px;

            img {
              width     : 100%;
              height    : 100%;
              object-fit: contain;
            }
          }

          //  justify-content: space-between;
          .modal_name_box {

            .modal_good_title {
              color: var(--color_black2);
            }

            .modal_good_price {
              color      : var(--brand_color_black);
              line-height: 63px;
            }
          }
        }

        .modal_option_block {
          margin-left: 60px;

          .product__counter__wrapper {
            display    : flex;
            align-items: flex-end;

            .decr {
              background-color: transparent;
              border          : 1px solid var(--gray4);
              border-radius   : 8px;
              width           : 32px;
              height          : 32px;
              line-height     : 32px;
            }

            .incr:disabled,
            .decr:disabled {
              cursor: not-allowed;
            }

            &__total {
              margin       : 0px 10px;
              width        : 35px;
              height       : 35px;
              border-radius: 8px;
              border       : 1px solid var(--gray4);
              color        : var(--brand_color_red);
              display      : inline-block;
              text-align   : center;
              line-height  : 35px;
            }

            .incr {
              background-color: transparent;
              border          : 1px solid var(--gray4);
              border-radius   : 8px;
              width           : 32px;
              height          : 32px;
              line-height     : 32px;
            }
          }

          .product__action__wrapper {
            margin-top: 15px;
            text-align: right;
          }

          img {
            cursor: pointer;
          }

          .delete_icon {
            margin-left: 30px;
          }
        }

      }
    }

    .ant-radio-group {
      display: block;

      .installment_modal_collapse.ant-collapse {
        border: none;

        .ant-collapse-item {
          border       : 1px solid #d9d9d9;
          margin-top   : 20px;
          background   : #fafafa;
          border-radius: 5px;

          .ant-collapse-header {
            display    : block;
            user-select: none;
            padding    : 0;

            &-text {
              .good_option_block {
                width        : 100%;
                padding      : 12px 16px;
                padding-right: 40px;

                .ant-radio-wrapper.installment_radio {
                  width: 100%;

                  .ant-radio-checked {
                    &::after {
                      border: 1px solid var(--brand_color_red);
                    }
                  }

                  .ant-radio-inner {
                    border   : 1px solid var(--color_black2);
                    // width : 24px;
                    // height: 24px;

                    &::after {
                      background-color   : var(--brand_color_red);
                      // width           : 23px;
                      // height          : 23px;
                      // margin          : 0;
                      // top             : 0;
                      // left            : 0;
                    }
                  }

                  .intallment__brand__img {
                    width       : 120px;
                    height      : 35px;
                    margin-right: 32px;

                    img {
                      width     : 100%;
                      height    : 100%;
                      object-fit: contain;
                    }
                  }

                  .ant-select.installment_selector_periud {
                    border    : 1px solid var(--gray6);
                    box-shadow: none;

                    .ant-select-selector {

                      .ant-select-selection-item {
                        position: static;
                      }
                    }
                  }

                  .good_option_installment {
                    margin-left: 30px;
                  }

                  span.ant-radio+* {
                    display    : flex;
                    align-items: center;
                    margin-left: 20px;
                  }
                }
              }
            }

            .ant-collapse-expand-icon {
              .ant-collapse-arrow {
                padding         : 8px;
                border          : 1px solid var(--gray6);
                background-color: #fff;
                border-radius   : 5px;
              }
            }
          }

          .ant-collapse-content {
            .ant-collapse-content-box {
              .installment__content {
                &__text {
                  margin-bottom: 30px;
                  position     : relative;
                  padding-left : 20px;

                  &::after {
                    content         : "";
                    position        : absolute;
                    top             : 0;
                    left            : 0;
                    width           : 4px;
                    height          : 100%;
                    background-color: var(--brand_color_red);
                  }
                }

                &__title {
                  background-color: #f7f7f7;
                  padding         : 10px;
                  border-radius   : 6px;
                  font-family     : "GothamPro-Medium", sans-serif;

                }

                &__item {
                  padding      : 10px;
                  display      : flex;
                  align-items  : center;
                  border-bottom: 1px solid var(--gray5);

                  .item__title,
                  .item__value {
                    width: 50%;
                  }
                }
              }
            }
          }

          .ant-collapse-content {
            border: none;
          }
        }

        .ant-collapse-item-active {
          border: 1px solid var(--brand_color_red);

        }

      }

    }

    .installment_Modal_buttons {
      margin         : 30px 0px;
      display        : flex;
      justify-content: flex-end;

      .ant-btn.modal_make_purchase {
        background   : var(--brand_color_red);
        border-color : var(--brand_color_red);
        color        : #fff;
        border-radius: 4px;
      }

      .ant-btn.modal_continue_purchase {
        color        : var(--brand_color_red);
        border-color : var(--brand_color_red);
        border-radius: 4px;
        margin-right : 15px;
      }
    }
  }
}