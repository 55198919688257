.product_comparison {
  padding: 30px 0px 0px;

  .product_comparison_breadcrumb_area {}

  .product_comparison_title {
    color     : var(--brand_color_black);
    margin-top: 30px;
  }

  .product_comparison_body {
    overflow-x    : auto;
    padding-bottom: 24px;


    .compare_nav {
      margin-top: 15px;
      position  : sticky;
      left      : 0;
      min-height: 42px;
      overflow  : scroll;

      .compare_nav_item {
        margin-right : 30px;
        margin-top   : 15px;
        display      : inline-block;
        padding      : 5px 35px 4px 20px;
        border       : 1px solid var(--gray5);
        border-radius: 20px;
        color        : var(--brand_color_black);
        display      : inline-flex;
        align-items  : center;

        &:last-child {
          margin-right: 0;
        }

        position : relative;

        .compare_item {

          cursor     : pointer;
          user-select: none;

        }

        .detete_compare_item {
          border          : none;
          background-color: transparent;
          outline         : none;
          cursor          : pointer;
          font-size       : 18px;
          position        : absolute;
          top             : 20%;
          right           : 10px;
          z-index         : 11;
          color           : var(--brand_color_black);
        }
      }

      .compare_nav_item.active {
        background-color: var(--brand_color_red);
        border-color    : var(--brand_color_red);

        .compare_item {
          color: #fff;
        }

        .detete_compare_item {
          color: #fff;
        }
      }

    }

    // .compare_change {
    //   display        : flex;
    //   justify-content: space-between;
    //   margin         : 25px 0;
    //   align-items    : flex-end;

    // .compare_change_difference {


    //   .difference_text {
    //     color      : var(--brand_color_black);
    //     margin-left: 10px;
    //     line-height: 25px;
    //   }


    // }
    // }
    .compare__product__add {
      position  : sticky;
      left      : 0;
      text-align: right;
      margin    : 25px 0;

      .compare__product__add__link {
        position: relative;

        &::before {
          content      : "\2b";
          position     : absolute;
          font-family  : "Font Awesome 6 Free";
          font-size    : 15px;
          font-weight  : 900;
          color        : var(--color_black2);
          top          : -10px;
          left         : -40px;
          width        : 30px;
          height       : 30px;
          border-radius: 50%;
          text-align   : center;
          line-height  : 28px;
          border       : 0.3px solid var(--color_black2);
        }

        color : var(--brand_color_black);
      }
    }


    .product_comparison_products {
      .ant-row {
        flex-flow: nowrap;
      }
    }

    .description__product__title {
      color        : var(--brand_color_black);
      position     : sticky;
      left         : 0;
      margin-top   : 60px;
      margin-bottom: 15px;
    }

    .description__product__row__title {
      margin-bottom: 5px;
      color        : var(--color_black2);
      position     : sticky;
      left         : 0;
    }

    .description__product__row {
      .content {
        color: var(--brand_color_black);
      }
    }
  }
}