.select__wrapper {
  position: relative;
  margin-top: 10px;

  .ant-select.select__comp {
    font-size: 14px;

    .ant-select-selector {
      border-radius: 6px;
      height: 44px;

      .ant-select-selection-item {
        line-height: 44px;
      }
    }
  }

  .custom__label {
    position: absolute;
    top: 10px;
    left: 13px;
    padding: 0px 4px 0px 4px;
    pointer-events: none;
    transform: scale(0.95) translateY(-1.4rem) translateX(-0.1rem);
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    color: #767676;
    margin: 0;
    font-size: 14px;
    line-height: 24px;
  }

  .error_message {
    color: var(--brand_color_red);
    font-size: 14px;
  }
}
