.product_view_carusel {
  .img_body img {
    width     : 100%;
    height    : 100%;
    object-fit: cover;
    cursor    : pointer;
  }

  .mySwiper2 {
    height: 80%;
    width : 100%;

    .img_body {
      padding      : 30px;
      border       : 1px solid var(--gray6);
      border-radius: 5px;

      img {
        width     : 100%;
        height    : 280px;
        object-fit: contain;
      }
    }
  }

  .product_view_bottom {
    position: relative;

    .mySwiper {
      width     : calc(100% - 36px);
      margin    : 0 auto;
      position  : unset;
      box-sizing: border-box;
      padding   : 10px 0;

      .swiper-wrapper {
        .swiper-slide {
          display      : grid;
          place-items  : center;
          height       : 70px;
          border       : 1px solid var(--gray6);
          border-radius: 5px;
          cursor       : pointer;
        }

        .img_body {
          padding: 10px;
          width  : 70px;
          height : 70px;
        }

        .swiper-slide.swiper-slide-visible.swiper-slide-thumb-active {
          border-color: var(--brand_color_red);
        }
      }
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top             : 50%;
      transform       : translateY(40%);
      background-color: var(--gray5);
      border-radius   : 50%;
      width           : 25px;
      height          : 25px;

      &::after {
        font-size  : 14px;
        font-weight: bold;
        color      : #333;
      }
    }
  }
}