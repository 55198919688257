.popular_categories_slider {
  .popular_slider_title {
    margin: 80px 0px 30px;
    color: var(--brand_color_black);
  }

  .slider_wrapper {
    position: relative;

    .swiper {
      position: static;

      .swiper-button-next {
        right: -30px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--color_white2);
        border: 1px solid #e0e0e0;

        &:after {
          font-size: 14px;
          color: #808080;
        }
      }

      .swiper-button-prev {
        left: -30px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--color_white2);
        border: 1px solid #e0e0e0;

        &:after {
          font-size: 14px;
          color: #808080;
        }
      }

      .swiper-slide {
        transition: 0.5s ease-in-out;
        height: 250px;
        padding: 16px;

        .card {
          width: 230px;
          height: 230px;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          .popular__slider__card {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .img_card {
            width: 100%;
            height: 80%;
            border-bottom: 0.2px solid silver;
            border-radius: 10px;
          }

          .card_title {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 5px 0;
          }
        }
      }
    }
  }
}
