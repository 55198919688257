:root {
  --brand_color_red: #ed3729;
  --brand_color_yellow: #ff6f43;
  --brand_color_black: #202020;
  --color_black2: #909090;
  --blue1: #2f80ed;
  --gray1: #8f8e94;
  --gray4: #bdbdbd;
  --gray5: #e0e0e0;
  --gray6: #f2f2f2;
  --color_white2: #f7f7f7;
  --daraja2: #626364;
  --blue1: #2f80ed;
}
